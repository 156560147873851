<template>
	<div class="columns">
		<b-notification
			:closable="false"
			class="notification"
		>
			<b-loading
				v-model="loading"
				:is-full-page="true"
				:can-cancel="false"
			/>
		</b-notification>
	</div>
</template>

<script>
import { get } from 'lodash';
import http from '@commons/http';

export default {
	name: "EstagioObrigatorio",
	data() {
		return {
			acessos: [],
			loading: true
		}
	},
	async created() {
		await this.openUrl();
	},
	methods:{
		get,
		openUrl(){
			http.get('/v1/valorizza/url').then(({data})=>{
				const url = data.url;
				if(url == undefined || data.status == "error"){
					this.$buefy.toast.open({
						message: 'Erro no redirecionamento!',
						duration: 4000,
						type: 'is-danger'
					});
					this.$router.push({ name: 'Home' });
				}else{
					window.location.href = url;
				}
			});
		},
	}
}
</script>
